import htmx from 'htmx.org'

import lazy from './js/lazy.js'
import animations from './js/animations.js'
import header from './js/header.js'
import ras from './js/ras.js'
import slider from './js/slider.js'
import toggle from './js/toggle.js'

header.init();
ras.init();

lazy.init();
animations.init();
slider.init();
toggle.init();


function htmxBefore(evt) {
}

function htmxAfter(evt) {
  lazy.init();
  animations.init();
  slider.reset();
  toggle.reset();

  if (evt.target.classList.contains('js-htmx-main')) {
    setTimeout(() => {
   
    }, 500);
    window.scrollTo(0, 0);
  }
}


htmx.on("htmx:beforeSend", function(evt) {
  console.log('htmx:beforeSend');
  htmxBefore(evt);
});

htmx.on("htmx:historyRestore", function(evt) {
  console.log('htmx:historyRestore');
  htmxAfter(evt);
});

htmx.on("htmx:afterSwap", function(evt) {
  console.log('htmx:afterSwap');
  htmxAfter(evt);
});

/*
// Helper function
let domReady = (cb) => {
  document.readyState === 'interactive' || document.readyState === 'complete'
    ? cb()
    : document.addEventListener('DOMContentLoaded', cb);
};

domReady(() => {
  // Display body when DOM is loaded
  document.body.style.visibility = 'visible';
});


function htmxBefore(evt) {

  if (document.querySelectorAll('.js-htmx-filter').length > 0) {
    document.querySelectorAll('.js-htmx-filter').forEach(function(el) {
      el.addEventListener('click', function() {
        document.querySelectorAll('.js-htmx-filter').forEach(function(el2) {
          el2.classList.remove('is-active');
        });

        el.classList.add('is-active');
      });
    });
  }

  if (!evt.target.classList.contains('js-htmx-loadmore') && !evt.target.classList.contains('js-htmx-search') && !evt.target.classList.contains('js-htmx-partial')) {
    document.querySelector('.js-main').classList.add('is-loading');
    jsToggle.disable('menu');
    jsToggle.disable('search');

    setTimeout(function() {
      window.scrollTo({
        top: 0,
        // behavior: "smooth"
      });
    }, 200);
  } else if (evt.target.classList.contains('js-htmx-search')) {
  } else if (evt.target.classList.contains('js-htmx-partial')) {
  } else {
    setTimeout(function() {
      window.scrollTo({
        top: 150,
        behavior: "smooth"
      });
    }, 200);

    document.querySelectorAll('.js-htmx-loadmore').forEach(function(el) {
      el.classList.remove('is-active');
    });
    evt.target.classList.add('is-active');

  }
}

function htmxAfter(evt) {


  // refresh HTMX
  htmx.process(document.body);

  setTimeout(function() {
    jsLazy.init();
    jsSlider.init();
    jsAccordion.init();
    jsForm.init();
    jsMouseScroll.init();
    jsMap.init();
    jsPassword.init();
    
    // console.log(evt.target.classList);
    
    document.querySelector('.js-main').classList.remove('is-loading');
    console.log('htmx:afterSwap');
    
  }, 200);
}

htmx.on("htmx:beforeSend", function(evt) {
  console.log('htmx:beforeSend');
  htmxBefore(evt);
});

htmx.on("htmx:historyRestore", function(evt) {
  htmxAfter(evt);
});

htmx.on("htmx:afterSwap", function(evt) {
  console.log('htmx:afterSwap');
  htmxAfter(evt);
});

/*
import App from './App.svelte'

const app = new App({
  target: document.getElementById('app'),
})

export default app
*/