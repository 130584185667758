"use strict";

const toggle = {
    elements: {
        trigger: '.js-toggle-trigger',
        target: '.js-toggle-target',
    },
    clickHandler: function (e) {
        e.preventDefault();

        this.toggle(e);
    },
    init() {
        const self = this;
        self.instance = null;
        if (!document.querySelector(self.elements.trigger)) return false;

        // Bind the clickHandler function to 'this'
        self.clickHandler = self.clickHandler.bind(self);
        self.initClickListeners();
        self.initCloseListeners();
    },

    reset() {
        const self = this;
        self.removeClickListeners();
        self.removeCloseListeners();
        
        // Reset any relevant state or data as needed
        
        // Reinitialize the object/module
        self.init();
    },

    initClickListeners() {
        const self = this;
        document.querySelectorAll(self.elements.trigger).forEach(function (el) {
            el.addEventListener('click', self.clickHandler); // Use clickHandler here
        });

        document.querySelectorAll(self.elements.target).forEach(function (el) {
            el.addEventListener('click', self.clickHandler); // Use clickHandler here
        });

        
    },

    removeClickListeners() {
        const self = this;
        document.querySelectorAll(self.elements.trigger).forEach(function (el) {
            el.removeEventListener('click', self.clickHandler); // Use clickHandler here
        });

        document.querySelectorAll(self.elements.target).forEach(function (el) {
            el.removeEventListener('click', self.clickHandler); // Use clickHandler here
        });
    },

    initCloseListeners() {
        const self = this;
        document.querySelectorAll(self.elements.target).forEach(function (target) {
            target.querySelectorAll('a').forEach(function (el) {
                el.addEventListener('click', function (e) {
                    self.closeAll();
                });
            });

        });
        
        // Esc key
        document.addEventListener('keydown', function (e) {
            if (e.keyCode === 27) {
                self.closeAll();
            }
        });
    },

    removeCloseListeners() {
        const self = this;
        document.querySelectorAll(self.elements.target).forEach(function (target) {
            target.querySelectorAll('a').forEach(function (el) {
                el.removeEventListener('click', function (e) {
                    self.closeAll();
                });
            });
        });

        // Esc key
        document.removeEventListener('keydown', function (e) {
            if (e.keyCode === 27) {
                self.closeAll();
            }
        });
    },

    toggle(e) {
        const self = this,
            el = e.target,
            slug = el.getAttribute('data-toggle-slug'),
            group = el.getAttribute('data-toggle-group');

        if (el.classList.contains('is-active')) {
            el.classList.remove('is-active');

            document.querySelectorAll(`[data-toggle-slug="${slug}"]`).forEach(function (el) {
                el.classList.remove('is-active');
            });
        } else {
            el.classList.add('is-active');

            document.querySelectorAll(`[data-toggle-slug="${slug}"]`).forEach(function (thisEl) {
                thisEl.classList.add('is-active');
            });

            document.querySelectorAll(`[data-toggle-group="${group}"]`).forEach(function (thisEl) {
                if (thisEl.getAttribute('data-toggle-slug') != slug) {
                    thisEl.classList.remove('is-active');
                }
            });
            
        }
    },

    closeAll() {
        const self = this;
        document.querySelectorAll(self.elements.trigger).forEach(function (el) {
            el.classList.remove('is-active');
        });
        document.querySelectorAll(self.elements.target).forEach(function (el) {
            el.classList.remove('is-active');
        });
    }
};

export default toggle;
